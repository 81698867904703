
import { defineComponent, onMounted, watch, nextTick, computed, ref, onUpdated, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import TheActivityEditModal from '@/components/builder/TheActivityEditModal.vue'
import TheWidgetAddModal from '@/components/builder/TheWidgetAddModal.vue'
import TheWidgetSolutionModal from '@/components/builder/TheWidgetSolutionModal.vue'
// import BuilderBottomMenu from '@/views/mc-admin-builder/BuilderBottomMenu.vue'
import BuilderWorkspace from '@/views/mc-admin-builder/BuilderWorkspace.vue'
// import BuilderSectionMenu from '@/views/mc-admin-builder/BuilderSectionMenu.vue'
import BuilderMobileTopMenu from '@/views/mc-admin-builder/BuilderMobileTopMenu.vue'
import BuilderMobileBottomMenu from '@/views/mc-admin-builder/BuilderMobileBottomMenu.vue'
// import BuilderWidgetMenu from '@/views/mc-admin-builder/BuilderWidgetMenu.vue'
// import BuilderJitsiMenu from '@/views/mc-admin-builder/BuilderJitsiMenu.vue'
import HtmlClass from '@/core/services/LayoutService'
import KTLoader from '@/components/Loader.vue'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { MenuComponent } from '@/assets/ts/components'
import { reinitializeComponents } from '@/core/plugins/keenthemes'
import { removeModalBackdrop } from '@/core/helpers/dom'
import { loaderEnabled, loaderLogo } from '@/core/helpers/config'
import { uuid } from 'vue-uuid'
import VueGridLayout from 'vue3-grid-layout'
import { generateWidget, sectionColorClassMap } from '@/core/helpers/template/widget'
import { Widget } from '@/core/helpers/template/TemplateInterface'
import { ElNotification } from 'element-plus/es'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import KTUserMenu from '@/layout/header/partials/OpenAiDrawerBuilder.vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import TheIconFinderModal from '@/views/mc-admin-builder/TheIconFinderModal.vue'
import TheLockSectionModal from '@/components/builder/TheLockSectionModal.vue'
import { buildTemplateFileUri } from '@/core/mc-admin-helpers/activity-template'
import TextCardStyled, { styledTextCardBackgroundColors } from '@/views/mc-admin-builder/Widget/TextCardStyled.vue'
import QuestionTeamReadiness from '@/views/mc-admin-builder/Widget/QuestionTeamReadiness.vue'
import TheRegenerateWidgetModal from '@/components/builder/TheRegenerateWidgetModal.vue'
import Generate360SkyboxImageModal from '@/components/builder/Generate360SkyboxImageModal.vue'
// import TheRegenerateSectionModal from '@/components/builder/TheRegenerateSectionModal.vue'
import AvatarSettingsModal from '@/views/mc-admin-builder/Widget/ChattyAvatar/AvatarSettingsModal.vue'
import PlatformGuideSettings from '@/views/mc-admin-builder/Widget/ChattyAvatar/PlatformGuideSettings.vue'
export default defineComponent({
  name: 'Layout',
  components: {
    PlatformGuideSettings,
    // TheRegenerateSectionModal,
    Generate360SkyboxImageModal,
    TheRegenerateWidgetModal,
    QuestionTeamReadiness,
    TextCardStyled,
    TheLockSectionModal,
    TheActivityEditModal,
    TheWidgetAddModal,
    TheWidgetSolutionModal,
    // BuilderBottomMenu,
    BuilderWorkspace,
    // BuilderSectionMenu,
    // BuilderWidgetMenu,
    // BuilderJitsiMenu,
    BuilderMobileTopMenu,
    BuilderMobileBottomMenu,
    KTLoader,
    KTUserMenu,
    TheIconFinderModal,
    AvatarSettingsModal
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const socket = computed(() => store.getters.getSocket)
    const emitter = inject('emitter') as any
    const showIconFinderModal = ref(false)
    const showLinkToWidgetModal = ref(false)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const modalDefaultWidth = {
      'text-card-styled': '400px',
      'question-team-readiness': '785px'
    }
    const handleShowIconfinder = (show: boolean) => {
      if (!show) {
        emitter.emit('close-iconfinder')
      }
      showIconFinderModal.value = show
    }
    const templateUuid = useRouter().currentRoute.value.params.id
    const currentAccount = computed(() => store.getters.currentAccount)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const sections = computed(() => store.getters.getSections)
    const activeSection = computed(() => store.getters.getActiveSection)
    const activeWidget = computed(() => store.getters.getActiveWidget)
    const activeSectionWidgetsLayout = computed(() => store.getters.getActiveSectionWidgetsLayout)
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const activeWidgetIndex = computed(() => store.getters.getActiveWidgetIndex)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)

    const builderWorkspace = ref() as any
    const mousePosition = { x: 0, y: 0 }
    const dragPosition = { x: 0, y: 0, w: 0, h: 0, color: '' }
    let mouseInWorkspace = false as boolean
    let workspaceRect = {} as DOMRect
    let gridLayout = {} as VueGridLayout.GridLayout
    const workspaceScale = ref(1)
    const onWorkspaceScaleChanged = (scale) => {
      emitter.emit('scaling-workspace', scale)
      workspaceScale.value = scale
    }
    const onWidgetGroupMove = (widgetLayout) => {
      gridLayout.dragEvent(['dragstart', widgetLayout.widgetUuid, widgetLayout.newPosX, widgetLayout.newPosY, widgetLayout.h, widgetLayout.w])
    }

    const onWidgetGroupMoved = (widgetLayout) => {
      gridLayout.dragEvent(['dragend', widgetLayout.widgetUuid, widgetLayout.newPosX, widgetLayout.newPosY, widgetLayout.h, widgetLayout.w])

      // persist widget layout changes
      const locatorInstance = buildWidgetLocator(activityTemplate, widgetLayout.widgetUuid)
      const widget = computed(() => store.getters.getWidgetByUuid(widgetLayout.widgetUuid))
      if (widget) {
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: locatorInstance.activeSectionIndex,
            activeWidgetIndex: locatorInstance.activeWidgetIndex
          },
          widgetPosition: widget.value.position
        }

        socket.value.emit('EDIT_LAYOUT_SECTION', {
          eventType: 'EDIT_LAYOUT_SECTION',
          payload: socketPayload
        })
      }
    }

    emitter.on('show-iconfinder', (show) => {
      showIconFinderModal.value = show
    })

    emitter.on('widget-drag-workspace', (widgetType) => {
      drag(widgetType)
    })

    const drag = (widgetType) => {
      const dummyWidgetIndex = activeSectionWidgetsLayout.value.findIndex(item => item.i === 'dummy')

      // first, add a dummy widget far outside the grid
      if (dummyWidgetIndex === -1 && mouseInWorkspace) {
        const widget: Widget | null = generateWidget(widgetType, 1, 1)
        if (widget) {
          // todo remove colors for all widgets and keep it to flashcard for now
          dragPosition.color = widget.specific?.color
          widget.widgetUuid = 'dummy'
          const payload = {
            widget: widget
          }
          store.commit(Mutations.SET_TEMPLATE_ADD_WIDGET, payload)
        }
      }

      // if the dummy widget exists start dragging it for position preview
      if (dummyWidgetIndex !== -1) {
        const gridItem = builderWorkspace.value.$refs.setItemRef[dummyWidgetIndex]
        const placeholderCenterX = parseInt(gridItem.style.width.split('px')[0]) * 0.5
        const placeholderCenterY = parseInt(gridItem.style.height.split('px')[0]) * 0.5
        const x = (mousePosition.x - workspaceRect.left - placeholderCenterX - (workspaceRect.x / 480)) / workspaceScale.value
        const y = (mousePosition.y - workspaceRect.top - placeholderCenterY - (workspaceRect.y / 270)) / workspaceScale.value
        const newPosition = gridItem.calcXY(y, x)

        const dummyWidget = activeSectionWidgetsLayout.value[dummyWidgetIndex]

        if (mouseInWorkspace) {
          gridLayout.dragEvent(['dragstart', 'dummy', newPosition.x, newPosition.y, dummyWidget.h, dummyWidget.w])
          dragPosition.x = dummyWidget.x
          dragPosition.y = dummyWidget.y
          dragPosition.w = dummyWidget.w
          dragPosition.h = dummyWidget.h
        } else {
          gridLayout.dragEvent(['dragend', 'dummy', newPosition.x, newPosition.y, dummyWidget.h, dummyWidget.w])
          store.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, 'dummy')
        }
      }
    }

    emitter.on('widget-dragend-workspace', (widgetType) => {
      dragend(widgetType)
    })

    const dragend = (widgetType) => {
      if (mouseInWorkspace) {
        // first remove the dummy widget
        gridLayout.dragEvent(['dragend', 'dummy', dragPosition.x, dragPosition.y, dragPosition.h, dragPosition.w])
        store.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, 'dummy')

        // inform use if widget could not be placed in grid
        if (dragPosition.x > gridLayout.$props.colNum || dragPosition.y > gridLayout.$props.maxRows) {
          ElNotification.warning({
            message: 'The widget is <b>too big</b>, and it can not be placed there.',
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification'
          })
          return false
        }

        // if position looks good proceed to add the real widget
        const widgetUuid = uuid.v4()
        const widget: Widget | null = generateWidget(widgetType, dragPosition.x, dragPosition.y)
        if (widget) {
          // apply the color back from dummy widget
          widget.specific.color = dragPosition.color
          const socketPayload = {
            templateUuid: activityTemplate.value.activityTemplateUuid,
            locator: {
              activeSectionIndex: activeSectionIndex.value
            },
            widget: widget
          }
          socket.value.emit('ADD_WIDGET', {
            eventType: 'ADD_WIDGET',
            payload: socketPayload
          })

          gridLayout.dragEvent(['dragend', widgetUuid, dragPosition.x, dragPosition.y, dragPosition.h, dragPosition.w])
          setTimeout(() => {
            reinitializeComponents()
          }, 100)
        } else {
          // if widget was not generated it is most likely not implemented
          ElNotification.warning({
            message: 'That widget is <b>not</b> available yet, and it can not be used to build templates.',
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification'
          })
        }
        // const widgetIndex = activeSectionWidgetsLayout.value.findIndex(item => item.i === widgetUuid)
        // builderWorkspace.value.$refs.setItemRef[widgetIndex].$refs.item.style.display = 'block'
      }
    }

    const dragIcon = (iconWidget) => {
      const dummyWidgetIndex = activeSectionWidgetsLayout.value.findIndex(item => item.i === 'dummy')

      // first, add a dummy widget far outside the grid
      if (dummyWidgetIndex === -1 && mouseInWorkspace) {
        const widget: Widget | null = generateWidget(iconWidget.type, 1, 1)
        if (widget) {
          // todo remove colors for all widgets and keep it to flashcard for now
          dragPosition.color = widget.specific?.color
          widget.widgetUuid = 'dummy'
          const payload = {
            widget: widget
          }
          store.commit(Mutations.SET_TEMPLATE_ADD_WIDGET, payload)
        }
      }

      // if the dummy widget exists start dragging it for position preview
      if (dummyWidgetIndex !== -1) {
        const gridItem = builderWorkspace.value.$refs.setItemRef[dummyWidgetIndex]
        const placeholderCenterX = parseInt(gridItem.style.width.split('px')[0]) * 0.5
        const placeholderCenterY = parseInt(gridItem.style.height.split('px')[0]) * 0.5
        const x = (mousePosition.x - workspaceRect.left - placeholderCenterX - (workspaceRect.x / 480)) / workspaceScale.value
        const y = (mousePosition.y - workspaceRect.top - placeholderCenterY - (workspaceRect.y / 270)) / workspaceScale.value
        const newPosition = gridItem.calcXY(y, x)

        const dummyWidget = activeSectionWidgetsLayout.value[dummyWidgetIndex]

        if (mouseInWorkspace) {
          gridLayout.dragEvent(['dragstart', 'dummy', newPosition.x, newPosition.y, dummyWidget.h, dummyWidget.w])
          dragPosition.x = dummyWidget.x
          dragPosition.y = dummyWidget.y
          dragPosition.w = dummyWidget.w
          dragPosition.h = dummyWidget.h
        } else {
          gridLayout.dragEvent(['dragend', 'dummy', newPosition.x, newPosition.y, dummyWidget.h, dummyWidget.w])
          store.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, 'dummy')
        }
      }
    }

    const dragendIcon = (iconWidget) => {
      if (mouseInWorkspace) {
        // first remove the dummy widget
        gridLayout.dragEvent(['dragend', 'dummy', dragPosition.x, dragPosition.y, dragPosition.h, dragPosition.w])
        store.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, 'dummy')

        // inform use if widget could not be placed in grid
        if (dragPosition.x > gridLayout.$props.colNum || dragPosition.y > gridLayout.$props.maxRows) {
          ElNotification.warning({
            message: 'The widget is <b>too big</b>, and it can not be placed there.',
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification'
          })
          return false
        }

        // if position looks good proceed to add the real widget
        // const widgetUuid = uuid.v4()
        const widget: Widget | null = generateWidget(iconWidget.type, dragPosition.x, dragPosition.y)
        if (widget) {
          const payload = {
            content: iconWidget.url,
            templateUuid: activityTemplate.value.activityTemplateUuid,
            widgetUuid: widget.widgetUuid
          }
          store.dispatch(Actions.API_UPLOAD_ICONFINDER_IMAGE, payload).then((result) => {
            // apply the color back from dummy widget
            widget.specific.color = dragPosition.color
            widget.specific.image = {}
            widget.specific.image.attachmentUuid = result.payload.meta.attachmentUuid
            const socketPayload = {
              templateUuid: activityTemplate.value.activityTemplateUuid,
              locator: {
                activeSectionIndex: activeSectionIndex.value
              },
              widget: widget
            }
            socket.value.emit('ADD_WIDGET', {
              eventType: 'ADD_WIDGET',
              payload: socketPayload
            })

            gridLayout.dragEvent(['dragend', widget.widgetUuid, dragPosition.x, dragPosition.y, dragPosition.h, dragPosition.w])
            setTimeout(() => {
              reinitializeComponents()
            }, 100)
          })
        } else {
          // if widget was not generated it is most likely not implemented
          ElNotification.warning({
            message: 'That widget is <b>not</b> available yet, and it can not be used to build templates.',
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification'
          })
        }
        // const widgetIndex = activeSectionWidgetsLayout.value.findIndex(item => item.i === widgetUuid)
        // builderWorkspace.value.$refs.setItemRef[widgetIndex].$refs.item.style.display = 'block'
      }
    }

    const widgetDelete = (widgetUuid) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        html: '<div>The widget and it\'s contents will be removed. This action is not reversible.</div>',
        animation: false,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          emitter.emit('confirm-widget-delete', widgetUuid)
          const locatorInstance = buildWidgetLocator(activityTemplate, widgetUuid)
          const socketPayload = {
            templateUuid: activityTemplate.value.activityTemplateUuid,
            widgetUuid: widgetUuid,
            locator: locatorInstance
          }
          socket.value.emit('REMOVE_WIDGET', {
            eventType: 'REMOVE_WIDGET',
            payload: socketPayload
          })
        }
      })
    }

    const openWidgetSettings = (widgetUuid) => {
      const widgetIndex = computed(() => store.getters.getWidgetIndexByUuid(widgetUuid)).value
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, widgetIndex)
    }

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    HtmlClass.init()
    const linkToWidget = ref()
    const usedHotspot = ref()
    const usedWidgetUuid = ref()
    const handlePersistWidgetStyledTextCard = (payload) => {
      // Ask Stefan About this commented line
      // usedHotspot.value.modalWidget.specific.content = payload.newValue
      const payloadEditHotspot = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: usedWidgetUuid.value,
        hotspot: usedHotspot.value
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payloadEditHotspot
      })
    }
    const handlePersistWidgetQuestionTeamReadiness = (payload) => {
      usedHotspot.value.modalWidget = payload.widget
      const payloadEditHotspot = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: usedWidgetUuid.value,
        hotspot: usedHotspot.value
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payloadEditHotspot
      })
    }

    const onClickLinkToWidgetDarkWhite = () => {
      if (usedHotspot.value.modalWidget.specific.textColor && usedHotspot.value.modalWidget.specific.textColor === 'dark') {
        usedHotspot.value.modalWidget.specific.textColor = 'white'
      } else {
        usedHotspot.value.modalWidget.specific.textColor = 'dark'
      }
      const payloadEditHotspot = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: usedWidgetUuid.value,
        hotspot: usedHotspot.value
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payloadEditHotspot
      })
    }

    const onClickLinkToWidgetRound = () => {
      usedHotspot.value.modalWidget.rounded = !usedHotspot.value.modalWidget.rounded
      const payloadEditHotspot = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: usedWidgetUuid.value,
        hotspot: usedHotspot.value
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payloadEditHotspot
      })
    }
    const onClickLinkToWidgetSetColor = (color) => {
      usedHotspot.value.modalWidget.specific.color = color
      const payloadEditHotspot = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: usedWidgetUuid.value,
        hotspot: usedHotspot.value
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payloadEditHotspot
      })
    }
    onMounted(() => {
      emitter.on('show-link-to-widget-modal', (payload) => {
        showLinkToWidgetModal.value = true
        linkToWidget.value = payload.hotspot.modalWidget
        usedHotspot.value = payload.hotspot
        usedWidgetUuid.value = payload.widgetUuid
        console.log('show-link-to-widget-modal', linkToWidget.value)
      })
      emitter.on('drag-icon', (icon) => {
        dragIcon(icon)
      })
      emitter.on('dragend-icon', (icon) => {
        dragendIcon(icon)
      })
      watch(currentAccount, () => {
        store.dispatch(MutationsActions.API_GET_MUTATE_TEMPLATE, templateUuid)
        window.dispatchEvent(new Event('resize'))
      })
      if (currentAccount.value) {
        store.dispatch(MutationsActions.API_GET_MUTATE_TEMPLATE, templateUuid)
        window.dispatchEvent(new Event('resize'))
      }
      watch(activityTemplate, (newVal, oldVal) => {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, 0)
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })
      nextTick(() => {
        reinitializeComponents()
      })
    })

    onUpdated(() => {
      gridLayout = builderWorkspace.value.$refs.gridLayout
      workspaceRect = document.getElementById('builder-workspace')!.getBoundingClientRect()

      window.addEventListener('resize', function () {
        if (document.getElementById('builder-workspace')) {
          workspaceRect = document.getElementById('builder-workspace')!.getBoundingClientRect()
        }
      }, false)

      document.addEventListener('dragover', function (e) {
        mousePosition.x = e.clientX
        mousePosition.y = e.clientY
        mouseInWorkspace = ((mousePosition.x > workspaceRect.left) && (mousePosition.x < workspaceRect.right)) &&
                    ((mousePosition.y > workspaceRect.top) && (mousePosition.y < workspaceRect.bottom))
      }, false)
      reinitializeComponents()
    })

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined)

        // check if current user is authenticated
        if (!store.getters.isAccountAuthenticated) {
          window.location.href = '/sign-in'
          // useRouter().push({ name: 'sign-in' })
        }

        removeModalBackdrop()
        nextTick(() => {
          reinitializeComponents()
        })
      }
    )

    const sectionBackgroundStyle = computed(() => {
      const style = {}

      if (activeSection.value.backgroundImage !== undefined && activeSection.value.backgroundImage !== null) {
        const backgroundImage = buildTemplateFileUri(activeSection.value.backgroundImage.attachmentUuid, currentAccount.value.company.name, activeSection.value.backgroundImage)
        style['background-image'] = `url(${backgroundImage})`
        style['background-repeat'] = 'no-repeat'
        style['background-size'] = 'cover'
        style['background-position'] = 'initial'
      }

      return style
    })

    return {
      loaderEnabled,
      loaderLogo,
      modalDefaultWidth,
      sections,
      activeSection,
      activeWidget,
      activeSectionIndex,
      activeWidgetIndex,
      widgetDelete,
      dragend,
      drag,
      openWidgetSettings,
      builderWorkspace,
      activityTemplate,
      onWorkspaceScaleChanged,
      onWidgetGroupMove,
      onWidgetGroupMoved,
      showIconFinderModal,
      handleShowIconfinder,
      handlePersistWidgetStyledTextCard,
      dragIcon,
      dragendIcon,
      mobileViewMode,
      solvingMode,
      sectionColorClassMap,
      styledTextCardBackgroundColors,
      sectionBackgroundStyle,
      showLinkToWidgetModal,
      linkToWidget,
      onClickLinkToWidgetRound,
      onClickLinkToWidgetSetColor,
      onClickLinkToWidgetDarkWhite,
      handlePersistWidgetQuestionTeamReadiness
    }
  }
})
