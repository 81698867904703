
import { computed, defineComponent, inject, onMounted, ref, unref, watch } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
// import Editor from '@tinymce/tinymce-vue'
import { QuillEditor as quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.core.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
export default defineComponent({
  name: 'the-widget-solution-modal',
  components: {
    quill
    // editor: Editor
  },
  setup () {
    const emitter = inject('emitter') as any

    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const activeWidget = computed(() => store.getters.getActiveWidget)
    const modalRef = ref()
    const explanation = ref()
    const widgetCategory = ref()
    const quillOptions = ref({
      modules: {
        toolbar: true
      }
    })

    emitter.on('explanation-clicked', (widget) => {
      const selectedWidget = computed(() => store.getters.getWidgetByUuid(widget.widgetUuid))
      explanation.value = selectedWidget.value.specific.explanation
    })
    watch(activeWidget, () => {
      if (activeWidget.value) {
        explanation.value = activeWidget.value.specific.explanation
        widgetCategory.value = activeWidget.value.category
      } else {
        explanation.value = ''
      }
    })

    const persistWidget = () => {
      console.log('Persisting widget...')

      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: activeWidget.value
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    const onWidgetSettingsSave = () => {
      if (activeWidget.value.category === 'action') {
        console.log('explanation', explanation)
        activeWidget.value.specific.explanation = unref(explanation)
        persistWidget()
      }
    }

    const editorSettings = {
      plugins: ['lists', 'paste', 'table', 'hr', 'link'],
      inline: false,
      menubar: false,
      toolbar_mode: 'sliding',
      toolbar: 'undo redo | bold italic underline strikethrough removeformat | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor | hr table link',
      paste_as_text: true,
      content_style: '.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before { left: 1.251vmin!important; } ' +
          ' p { margin: 0.3rem } ' + 'ul { margin: 0.3rem }' + 'ol { margin: 0.3rem }' + 'h1 { margin: 0.3rem }' + 'h2 { margin: 0.3rem }' + 'h3 { margin: 0.3rem }' +
          ' span[data-mce-style*="background-color"] { border-radius: 1.55vmin; padding: 0 6px; padding-bottom:1px }'
    }

    onMounted(() => {
      modalRef.value.addEventListener('hidden.bs.modal', function () {
        if (activeWidget.value.category === 'action') {
          explanation.value = activeWidget.value.specific.explanation
        }
      })

      // Prevent Bootstrap dialog from blocking focusin
      document.addEventListener('focusin', (e:any) => {
        if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
          e.stopImmediatePropagation()
        }
      })
    })

    return {
      editorSettings,
      explanation,
      widgetCategory,
      modalRef,
      activityTemplate,
      getApiKey,
      onWidgetSettingsSave,
      quillOptions
    }
  }
})
